@import "src/styles/helpers";

.circlesSliderSection {
    --content-padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 92px;
    padding: 64px 0;

    @include media(mobile) {
        gap: 64px;
    }

    @include media(portrait) {
        --content-padding: 64px;
        padding: 104px 0;
    }

    @include media(landscape) {
        --content-padding: 72px;
        padding: 120px 0;
    }

    @include media(desktopAll) {
        --content-padding: 320px;
        gap: 120px;
        padding: 120px 0;
    }

    @include media(desktopSmall) {
        --content-padding: 120px;
    }

    @include media(desktop) {
        --content-padding: 200px;
    }

    .headBlock {
        display: flex;
        justify-content: space-between;
        padding: 0 var(--content-padding);

        .headContent {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 506px;
        }

        .title {
            font-size: 14px;
            line-height: 28px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0.183px;
            text-transform: uppercase;
            @include setProperty(color, var(--secondary-200), var(--secondary-500));

            @include media(desktopAll) {
                font-size: 16px;
                line-height: 32px;
            }
        }

        .description {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: 'KharkivTone';
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(desktopAll) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .slider {
        display: flex;
        scrollbar-width: none;

        ul {
            @include media(notMobile) {
                transition: all ease-in-out 0.2s !important;
            }
        }
    }

    .navigation {
        display: none;
        gap: 24px;

        @include media(notMobile) {
            display: flex;
        }
    }

    .arrow {
        position: relative;
        z-index: 2;
        background-color: transparent;
        width: 72px;
        height: 52px;
        border-radius: 0;
        cursor: pointer;
        opacity: 0 !important;
        transition: all ease-in-out 0.3s;
      
        @include media(portrait) {
          display: block;
        }

        @include media(landscape) {
            width: 136px;
            height: 92px;
        }

        @include media(desktopAll) {
          display: block;
          width: 136px;
          height: 92px;
        }
      
        &_prev {
          img {
            transform: rotate(180deg)
          }
        }

        &.visible {
            opacity: 1 !important;
        }
      }
}

.circleItem {
    position: relative;
    border-radius: 500px;
    border: 1px solid;
    @include setProperty(border-color, var(--secondary-400), var(--light-line-shape));
    @include setProperty(background-color, var(--dark-bg), var(--light-bg));
    transition: all ease-in-out 0.5s;

    overflow: hidden;

    .contentWrapper {   
        width: 240px;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat, no-repeat;
        background-size: 408px 112px;
        background-position: center -200%, center 400%;
        transition: all ease-in-out 0.5s;
        opacity: 0.72;

        @include media(portrait) {
            width: 300px;
            height: 300px;
        }

        @include media(landscape) {
            width: 300px;
            height: 300px;
        }

        @include media(desktopAll) {
            width: 360px;
            height: 360px;
        }


        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.4;
        }

        .content {
            text-transform: uppercase;
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 0.183px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            text-align: center;

            @include media(desktopAll) {
                font-size: 16px;
                line-height: 32px;
            }
        }
    }

    &.active {
        .contentWrapper {
            background-position: center 0, center 100%;
            opacity: 1;

            .content {
                z-index: 1;
                animation: circleTextActive linear 1s;
            }

            &::before {
                opacity: 0.1;
                @include setProperty(background-color, var(--secondary-500), var(--secondary-50));
            }
        }

        @include setProperty(border-color, var(--dark-line-accent), var(--light-line-accent));
    }

    &.prev {
        margin-inline-start: -100px;

        @include media(mobile) {
            margin-inline-start: 0;
        }

        @include media(portrait) {
            margin-inline-start: 0;
        }
    }
}

@keyframes circleTextActive {
    0% {
        z-index: 0;
    }

    100% {
        z-index: 1;
    }
}
@import 'src/styles/helpers';

.circles {
    display: flex;
  }
  
  .circle {
    transition: none;
    @include setProperty(color, var(--primary-500), var(--primary-900));
  
    &:nth-child(1) {
      opacity: 0.2;
    }
  
    &:nth-child(2) {
      opacity: 0.3;
    }
  
    &:nth-child(3) {
      opacity: 0.6;
    }
  
    svg, img {
      width: 100%;
      height: auto;
    }
  
    &__border {
  
      circle {
        @include setProperty(stroke, var(--primary-500), var(--primary-900));
      }
    }
  }
@import 'src/styles/helpers';

.logo {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  svg {
    width: 100%;
    height: 100%;
  }
}

@import 'src/styles/helpers';

.revealingPointsSection {
    position: relative;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include media(portrait) {
        padding: 104px 0;
    }

    @include media(landscape) {
        padding: 120px 0;
        flex-direction: row;
        gap: 60px;
    }

    @include media(desktopAll) {
        padding: 120px 0;
        gap: 120px;
        flex-direction: row;
        justify-content: space-between;
    }

    .headBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;

        @include media(portrait) {
            gap: 48px;
        }

        @include media(landscape) {
            position: sticky;
            top: 240px;
            height: min-content;
            max-width: 45%;
            gap: 48px;
        }

        @include media(desktopAll) {
            position: sticky;
            top: 240px;
            height: min-content;
            max-width: 45%;
        }

        .descriptionBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            max-width: 300px;

            @include media(portrait) {
                max-width: 550px;
            }

            @include media(landscape) {
                max-width: 350px;
            }

            @include media(desktopAll) {
                max-width: 360px;
            }

            .title {
                text-transform: uppercase;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
            }
    
            .description {
                font-size: 14px;
                line-height: 28px;
                font-weight: 300;
                letter-spacing: 0.183px;
                @include setProperty(color, var(--secondary-50), var(--secondary-500));
                max-width: 343px;
            }
        }
    }

    .items {
        flex: 1 0 0;

        @include media(desktopAll) {
            max-width: 50%;
        }
    }
}

.itemIcon {
    max-width: 24px;
    max-height: 24px;
}

.item {
    display: flex;
    gap: 12px;
    padding: 40px 8px;
    border-bottom: 1px solid;
    @include setProperty(border-color, rgba(246, 255, 254, 0.05), rgba(81, 81, 81, 0.05));
    overflow: hidden;
    width: 100%;

    .itemIcon {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }

    .itemContent {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;

        .question {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }

        .answer {
            @include hideScrollbar;
            @include scrollOutOfContainer;
            font-size: 14px;
            line-height: 2;
            font-weight: 300;
            letter-spacing: 0.183px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
            display: none;
            white-space: break-spaces;

            @include media(desktopAll) {
                font-size: 16px;
            }
        }
    }

    .itemIcon {
        transition: transform .3s ease;
    }

    &.active {
        .itemContent {
            .question {
                @include setProperty(color, var(--primary-600), var(--primary-900));
            }

            .answer {
                display: block;
            }
        }

        .itemIcon {
            transform: rotate(90deg);
        }
    }
}
@import 'src/styles/helpers';

.social {
  @include setProperty(background, linear-gradient(310deg, #2A3634 0%, rgba(42, 54, 52, 0) 100%), linear-gradient(328.5deg, rgba(124, 124, 124, 0.4) 11.81%, rgba(204, 206, 206, 0.04) 88.09%));
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  overflow: hidden;

  @media screen and (max-width: 374px) {
    width: 40px;
    height: 40px;
  }

  @include media(desktopHuge) {
    width: 56px;
    height: 56px;
  }

  &__link {
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    @include setProperty(background, var(--dark-bg), var(--light-bg));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    @include setProperty(color, var(--primary-900), var(--secondary-200));

    svg {
      max-width: 24px;
      max-height: 24px;
      //width: 24px;
      //height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media(mobile) {
        max-width: 18px;
        max-height: 18px;
      }
    }

    &:hover {
      @include setProperty(color, var(--primary-500), var(--secondary-500));

      .social__link__particles {
        transform: none;
      }

      svg {

        path {
          @include setProperty(fill, var(--primary-500), var(--secondary-500));
        }
      }
    }

    &__particles {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transform: rotate(-80deg);
      transform-origin: top right;
      transition: all 0.3s ease;
    }
  }

  svg path {
    @include setProperty(fill, var(--primary-900), var(--secondary-200));
  }
}

@import "src/styles/helpers";

.AllServices {
  .hero {
    padding: calc(120px + 72px) 24px 64px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include media(portrait) {
      padding: calc(120px + 72px) 64px 104px;
    }

    @include media(landscape) {
      padding: calc(120px + 72px) 72px 120px;
    }

    @include media(desktopAll) {
      padding: calc(120px + 96px) 120px 80px;
    }

    &__title {
      font-family: "KharkivTone", sans-serif;
      font-size: 36px;
      line-height: 44px;
      margin: 0 auto;
      max-width: 1040px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      z-index: 3;

      @include media(desktopAll) {
        font-size: 60px;
        line-height: 68px;
      }
    }

    &__text {
      position: relative;
      z-index: 3;
      margin: 40px auto;
      max-width: 904px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      text-align: center;
      @include setProperty(color, var(--secondary-50), var(--secondary-500));

      @include media(desktopAll) {
        font-size: 16px;
        line-height: 32px;
      }
    }

    .ctaButton {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      height: 48px;
      font-size: 16px;

      @include media(mobile) {
        width: 100% !important;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include media(desktop) {
      max-width: 1040px;
    }

    @include media(desktopBig) {
      max-width: 1280px;
    }

    @include media(desktopHuge) {
      max-width: 1280px;
    }

    @include media(desktopSmall) {
      padding: 0 120px;
    }

    @include media(landscape) {
      padding: 0 72px;
    }

    @include media(portrait) {
      padding: 0 64px;
    }

    @include media(mobile) {
      padding: 0 24px;
    }
  }

  .contactContainer {
    @include media(mobile) {
      & > div {
        padding: 0 var(--container-padding-mobile);
      }
    }

    @include media(portrait) {
      & > div {
        padding: 0 var(--container-padding-portrait);
      }
    }

    @include media(landscape) {
      & > div {
        padding: 0 var(--container-padding-landscape);
      }
    }

    @include media(desktopAll) {
      & > div {
        padding: 0 var(--container-padding-desktopSmall);
      }
    }
  }

  .contact {
    background-color: transparent;
    padding: 64px 0px !important;

    @include media(portrait) {
      padding: 104px 0 !important;
    }

    @include media(landscape) {
      padding: 120px 0 !important;
    }

    @include media(desktopAll) {
      padding: 80px 0 !important;
    }

    @include media(desktop) {
      max-width: 1040px;
    }

    @include media(desktopSmall) {
      max-width: 1280px;
    }

    @include media(desktopBig) {
      max-width: 1280px;
    }

    @include media(desktopHuge) {
      max-width: 1280px;
    }
  }
}

.parallax {
  position: relative;

  @include media(mobile) {
    display: none;
  }

  * {
    pointer-events: none !important;
  }

  &__industries__noise {
    position: absolute;
    bottom: 840px;
    right: -200px;
    display: none;

    @include media(desktopHuge) {
      display: block;
    }
  }

  &__draw {
    position: absolute;
    bottom: 40px;
    right: -0px;

    @include media(portrait) {
      right: -300px;
    }

    @include media(desktopAll) {
      right: -200px;
    }
  }

  &__noise {
    position: absolute;
    bottom: 140px;
    right: 0;

    @include media(portrait) {
      right: 200px;
    }

    @include media(desktopAll) {
      right: 300px;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 240px;
    right: 0px;

    @include media(portrait) {
      display: none;
    }

    @include media(desktopAll) {
      right: -210px;
    }

    @include media(desktopBig) {
      right: -210px;
    }
  }
}

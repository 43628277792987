@import "src/styles/helpers";

.teamSliderSection {
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    .title {
        max-width: 440px;
        text-align: center;
        padding: 0 24px;
        white-space: break-spaces;

        @include media(desktopAll) {
            max-width: 640px;
        }
    }

    @include media(portrait) {
        padding: 104px 0;
        gap: 92px;
    }

    @include media(landscape) {
        padding: 120px 0;
        gap: 92px;
    }

    @include media(desktopAll) {
        padding: 120px 0;
        gap: 140px;
    }
}

.teamMember {
    position: relative;
    max-width: 224px;
    max-height: 315px;
    overflow: hidden;

    .memberImg {
        width: 100%;
        height: 100%;
        aspect-ratio: 4 / 5;
    }

    .socials {
        position: absolute;
        top: 12px;
        left: 12px;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .socialLink {
            background: none;
            border: 0.6px solid #2A3634;

            a {
              background: transparent;  
              color: var(--secondary-300);
            }
        }
    }

    .memberInfo {
        position: absolute;
        padding: 10px 24px 0 0;
        bottom: 0;
        left: 0;
        border-top-right-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        @include setProperty(background-color, var(--dark-bg), var(--light-bg));

        .memberName {
            font-family: 'KharkivTone', sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }

        .memberPosition {
            font-size: 14px;
            line-height: 28px;
            font-weight: 300;
            letter-spacing: 0.183px;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }
    }

    &:hover {
        .memberInfo {
            .memberName {
                color: var(--primary-500);
            }
        }
    }

    @include media(desktopAll) {
        max-width: 252px;
    }
    
    @include media(desktopSmall) {
        max-width: 224px;
    }
}
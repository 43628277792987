@import 'src/styles/helpers';

.filters {
  display: flex;
  gap: 24px;
  overflow-y: auto;
  @include hideScrollbar;
  @include scrollOutOfContainer;
  font-size: 14px;
  line-height: 1;

  @include media(notMobile) {
    flex-wrap: wrap;
  }
}

.filter {
  @include setProperty(color, var(--secondary-200), var(--secondary-300));
  text-transform: uppercase;
  flex-shrink: 0;
  transition: color 0.3s ease;
  @include setProperty(font-weight, 300, normal);

  &:hover {

    &:not(.active) {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
    }
  }

  &.active {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

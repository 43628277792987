@import "src/styles/helpers";

.statisticSection { 
    padding: 64px 0;

    @include media(notMobile) {
        padding: 120px 0;
    }

    @include media(portrait) {
        padding: 104px 0;
    }
    
    .content {
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 64px;

        @include media(notMobile) {
            gap: 92px;
        }

        @include media(desktopAll) {
            gap: 96px;
        }

        @include media(desktopSmall) {
            gap: 92px;
        }

        .title {
            max-width: 506px;
        }

        .description {
            font-size: 14px;
            line-height: 28px;
            font-weight: 300;
            letter-spacing: 0.183px;
            opacity: 0.6;
            @include setProperty(color, var(--secondary-50), var(--secondary-500));

            @include media(desktopAll) {
                font-size: 16px;
                line-height: 32px;
            }
        }

        .tiles {
            display: grid;
            grid-template-columns: repeat(1, auto);

            @include media(portrait) {
                grid-template-columns: repeat(2, minmax(0, auto));
            }

            @include media(landscape) {
                grid-template-columns: repeat(3, minmax(0, auto));
            }

            @include media(desktopAll) {
                grid-template-columns: repeat(3, minmax(0, auto));
            } 

            & > :nth-child(n) {
                border: 1px solid;
                border-left: none;
                border-bottom: none;
                @include setProperty(border-color, var(--dark-line-shape), var(--light-line-shape));

                @include media(mobile) {
                    border-right: none;
                }
            }

            :first-child {
                border-top: none;
            }

            & > :nth-child(2) {
                
                @include media(landscape) {
                    border-top: none;
                    border-right: none;
                }
                @include media(desktopAll) {
                    border-top: none;
                    border-right: none;
                }
            }

            :nth-child(odd) {
                @include media(portrait) {
                    border-right: none;
                }
            }

            :last-child {
                border-right: none;
            }

            .tile {
                padding: 32px 0;
                display: flex;
                flex-direction: column;
                gap: 24px;

                @include media(landscape) {
                    padding: 44px 24px;
                }

                @include media(desktopAll) {
                    padding: 52px 24px;
                }

                &:not(:nth-child(2n)){
                    border-bottom: none;

                    @include media(portrait) {
                        padding-left: 24px;
                    }
                }

                .value {
                    font-family: "KharkivTone";
                    font-weight: 400;
                    font-size: 36px;
                    line-height: 44px;
                    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    
                    @include media(desktopAll) {
                        font-size: 60px;
                        line-height: 68px;
                        letter-spacing: 1px;
                    }
                }
            }

            .tilesDescription {
                display: flex;
                align-items: end;
                padding: 0 0 32px 0;

                @include media(notMobile) {
                    grid-column: 2 span;
                    padding: 52px 24px 52px 0;
                }
            }
        }
    }
}

.tilesBg {
    position: absolute;
    right: -200px;
  
    .largestCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 0;
      top: 0;
    }
  
    .mediumCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 114px;
      top: 16px;
    }
  
    .smallCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 214px;
      top: 32px;
    }
  
    .smallestCircle {
      position: absolute;
      transform: rotate(90deg);
      right: 278px;
      top: 42px;
    }
  
    .noise {
      position: absolute;
      right: 300px;
      top: 180px;
    }
  
    .planetBig {
      position: absolute;
      top: 6px;
      right: 290px;
    }
  
    .planetSmall {
      position: absolute;
      top: 52px;
      right: 374px;
    }

    @include media(mobile) {
        display: none;
    }
  }
@mixin scrollOutOfContainer {
  @include media(mobile) {
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: calc(var(--container-padding-mobile) * -1);
    padding-right: var(--container-padding-mobile);
    margin-left: calc(var(--container-padding-mobile) * -1);
    padding-left: var(--container-padding-mobile);
  }

  @include media(portrait) {
    margin-right: calc(var(--container-padding-portrait) * -1);
    padding-right: var(--container-padding-portrait);
    margin-left: calc(var(--container-padding-portrait) * -1);
    padding-left: var(--container-padding-portrait);
  }

  @include media(landscape) {
    margin-right: calc(var(--container-padding-landscape) * -1);
    padding-right: var(--container-padding-landscape);
    margin-left: calc(var(--container-padding-landscape) * -1);
    padding-left: var(--container-padding-landscape);
  }

  @include media(tablet) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@import 'src/styles/helpers';

.button {
  padding: 10px 24px 7px;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 56px;
  width: 100%;
  font-family: 'KharkivTone', sans-serif;
  transition: all 0.3s ease;
  text-align: center;
  height: 48px;

  //&.big {
  //  font-size: 24px;
  //  padding: 24px 40px 21px;
  //  height: 80px;
  //}

  &.medium {
    padding: 17px 24px 14px;
  }

  &.disabled, &:disabled {
    pointer-events: none;
  }

  &.primary {
    @include setProperty(color, var(--dark-bg), var(--light-bg));
    @include setProperty(background, var(--primary-500), var(--primary-900));

    &:hover {
      @include setProperty(background, var(--primary-300), var(--primary-700));
    }

    &:active {
      @include setProperty(background, var(--primary-200), var(--primary-500));
    }

    &:disabled, &.disabled {
      @include setProperty(background, var(--secondary-400), var(--secondary-100));

      &:hover {
        @include setProperty(background, var(--secondary-400), var(--secondary-100));
      }
    }
  }

  &.outlined {
    @include setProperty(border, 1px solid var(--primary-500), 1px solid var(--secondary-500));
    @include setProperty(color, var(--primary-500), var(--secondary-500));

    &:hover {
      @include setProperty(background, var(--secondary-700), var(--secondary-50));
    }

    &:active {
      @include setProperty(background, var(--secondary-600), var(--secondary-100));
    }

    &:disabled, &.disabled {
      @include setProperty(border-color, var(--secondary-400), var(--secondary-200));
      @include setProperty(color, var(--secondary-400), var(--secondary-200));

      &:hover {
        background: transparent;
        @include setProperty(border-color, var(--secondary-400), var(--secondary-200));
        @include setProperty(color, var(--secondary-400), var(--secondary-200));
      }
    }
  }

  &.transparent {
    @include setProperty(color, var(--primary-500), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-200), var(--primary-900));
    }

    &:active {
      @include setProperty(color, var(--primary-200), var(--primary-900));
      @include setProperty(background, var(--secondary-600), var(--primary-50));
    }

    &:disabled, &.disabled {
      @include setProperty(color, var(--secondary-400), var(--secondary-200));
    }
  }

  &.link {
    padding: 0;
    text-decoration: underline;
    @include setProperty(color, var(--primary-500), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-200), var(--primary-900));
    }

    &:active {
      @include setProperty(color, var(--primary-50), var(--primary-500));
    }

    &:disabled, &.disabled {
      @include setProperty(color, var(--secondary-400), var(--secondary-200));
    }
  }

  &.withIcon {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-weight: normal;

    .icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: transform 0.3s ease;
      margin-left: 12px;
      margin-top: -2px;

      @at-root .big#{&} {
        margin-top: -4px;
      }

      svg, img {
        width: 100%;
        height: 100%;
      }
    }

    &_left {
      flex-direction: row-reverse;

      .icon {
        margin-right: 12px;
        margin-left: 0;
      }
    }
  }
}

@import "src/styles/helpers";

.challenges {
  padding-top: 30px;
  padding-bottom: 30px;

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  &__content {
    margin-top: 36px;
    display: grid;
    gap: 4px;

    @include media(tablet) {
      margin-top: 56px;
    }

    @include media(desktopAll) {
      margin-top: 56px;
    }
  }
}

.title {
  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

.challenge {
  border: 1px solid;
  @include setProperty(border-image, (linear-gradient(90deg, rgba(42, 54, 52, 0.8), rgba(42, 54, 52, 0.5), rgba(42, 54, 52, 0.1)) 10), (linear-gradient(90deg, rgba(163, 163, 163, 0.8), rgba(163, 163, 163, 0.5), rgba(163, 163, 163, 0.1)) 10));
  padding: var(--margin-md) 20px;

  @include media(tablet) {
    padding: var(--margin-xl) 56px;
  }

  &__title {
    font-size: 14px;
    line-height: 1;
    letter-spacing: var(--ls-183);
    @include setProperty(color, var(--primary-500), var(--primary-900));
    font-weight: bold;
    margin-bottom: var(--margin-sm);
  }

  &__value {
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;

    @include media(tablet) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
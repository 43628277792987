@import 'src/styles/helpers';

.imageBlock {
  position: relative;
  height: 240px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.round {
    outline: 8px solid;
    @include setProperty(outline-color, var(--secondary-500), var(--secondary-50));
    border-radius: 120px;
  }

  &.square {
    height: 390px;
  }


  @include media(mobile) {

    &.round,
    &.square {
      outline: none;
      border-radius: 0;
      width: calc(100% + var(--margin-md) * 2);
      margin-left: calc(var(--margin-md) * -1);
      position: relative;
    }
  }

  @include media(tablet) {
    &.round {
      outline-width: 16px;
      outline-style: solid;
    }

    &.square {
      height: 390px;
      border-radius: 0;
    }
  }

  @include media(desktopAll) {
    &.round {
      outline-width: 16px;
      outline-style: solid;
    }

    &.square {
      height: 390px;
      border-radius: 0;
    }
  }
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.content {
  position: relative;
  z-index: 1;
}